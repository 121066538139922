html {
  font-size: 16px;
}

/* Scrollbar customization */
::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #499e6e;
  border-radius: 5px;
}

::-moz-scrollbar {
  width: 5px;
  border-radius: 5px;
}

::-moz-scrollbar-track {
  background: #f1f1f1;
}

::-moz-scrollbar-thumb {
  background: #499e6e;
  border-radius: 5px;
}

a {
  color: #198754;
  text-decoration: none;
}
a:hover {
  color: #299d67;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 576px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 767.98px) {
  html {
    font-size: 15px;
  }
}

.animate__animated.animate__delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animate__animated.animate__delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animate__animated.animate__delay-6s {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

.animate__animated.animate__delay-7s {
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}

.content {
  width: 100wv;
  overflow: hidden;
}

.sticky-top {
  position: fixed;
  top: 0;
  z-index: 1020;
}

.link-navbar {
  font-weight: 500;
}

.link-navbar:hover {
  text-decoration: underline;
  color: #fff;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("/public/assets/dehaze.svg");
  box-shadow: none;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  border: none;
  box-shadow: none;
}

.font-lilita-one {
  font-family: 'Lilita One', cursive;
}

.font-lato {
  font-family: 'Lato', Helvetica;
}

.font-teko {
  font-family: "Teko", sans-serif;
}

.abs-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bench {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.name-logo {
  display: inline-block;
  font-family: 'Lilita One', cursive;
  color: #fff;
  font-size: 4rem;
}

.paralelogramo {
  height: 6px;
  background-color: rgb(255, 255, 255, .3);
  transform: skew(20deg);
}

.text-green {
  color: #198754;
}

.text-light-green {
  color: #D3E8DF;
}

.text-orange {
  color: #F2B641;
}

.text-gray {
  color: #89899E;
}

.text-purple {
  color: #403294;
}

.text-red {
  color: #FF0000;
}

.bg-light-orange {
  background-color: #F9D794;
}

.bg-white {
  background-color: #fff;
}

.bg-orange {
  background-color: #F2B641;
}

.bg-purple {
  background-color: #2227A7;
}

.bg-light-purple {
  background-color: #EAE6FF;
}

.bg-purple-200 {
  background-color: #d3cdf1;
}

.bg-purple-300 {
  background-color: #c3bce3;
}

.bg-green {
  background-color: #198754;
}

.bg-light-green {
  background-color: #D3E8DF;
}

.bg-green-200 {
  background-color: #9fcab7;
}

.bg-green-300 {
  background-color: #76b398;
}

.fs-7 {
  font-size: 0.9rem;
}

.fs-8 {
  font-size: 0.8rem;
}

.fs-9 {
  font-size: 0.7rem;
}

.fs-10 {
  font-size: 0.6rem;
}

.fs-large {
  font-size: 3rem;
}

.probar-gratis {
  display: inline-block;
  border: none;
  padding: 5px 15px;
  font-weight: 500;
}

.benefit-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.benefit-circle.small {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  font-size: 10px;
}

.image-process {
  display: block;
  width: 70%;
}

.section-functionality {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card-functionality {
  position: relative;
  display: block;
  max-width: 35rem;
  height: 10rem;
  margin: 5px 10px;
  border-radius: 20px;
  padding: 12px 7px;
  font-family: 'Lato', Helvetica;
  border: 2px solid transparent;
  cursor: pointer;
}

.card-functionality.pending::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "Próximamente";
  background-color: #3BCBB2;
  color: #fff;
  padding: 3px 5px;
  border-radius: 0px 20px 0px 0px;
}

@media screen and (max-width: 576px) {
  .image-process {
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
  }

  .card-functionality {
    height: 12rem;
    width: 25rem;
  }

  .card-functionality img {
    height: 6rem;
  }

  .probar-gratis {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 991.98px) {
  .image-process {
    margin-top: 2rem;
    width: 90%;
  }

  /* .card-functionality {
    height: 12rem;
    width: 25rem;
  }

  .card-functionality img {
    height: 6rem;
  } */

  .probar-gratis {
    margin-left: auto;
    margin-right: auto;
  }
}

.card-functionality p {
  font-size: .9rem;
  line-height: 15px;
}

.section-functionality .card-functionality:nth-child(1) {
  background-color: #DBD9F7;
}

.section-functionality .card-functionality:nth-child(2) {
  background-color: #FFDFD1;
}

.section-functionality .card-functionality:nth-child(3) {
  background-color: #FCE2EB;
}

.section-functionality .card-functionality:nth-child(4) {
  background-color: #DBF4F4;
}

.section-functionality .card-functionality:nth-child(5) {
  background-color: #CEFFD3;
}

.section-functionality .card-functionality:nth-child(6) {
  background-color: #C7CAFF;
}

/* Hover Card functionality */

.section-functionality .card-functionality:nth-child(1):hover {
  border: 2px solid #5048c3;
  box-shadow: 2px 2px 2px #8881eb;
}

.section-functionality .card-functionality:nth-child(2):hover {
  border: 2px solid #c07351;
  box-shadow: 2px 2px 2px #ecab90;
}

.section-functionality .card-functionality:nth-child(3):hover {
  border: 2px solid #af3f66;
  box-shadow: 2px 2px 2px #dc99b0;
}

.section-functionality .card-functionality:nth-child(4):hover {
  border: 2px solid #1a7777;
  box-shadow: 2px 2px 2px #59bdbd;
}

.section-functionality .card-functionality:nth-child(5):hover {
  border: 2px solid #369c40;
  box-shadow: 2px 2px 2px #66b26e;
}

.section-functionality .card-functionality:nth-child(6):hover {
  border: 2px solid #494d9e;
  box-shadow: 2px 2px 2px #6e72ba;
}

/* ----------------------------------------------------- */

.hr-style {
  margin: 5px 0px;
  color: #198754;
  border: 2px solid green;
}

.message {
  padding: 15px 20px;
  background-color: #D3E8DF;
  border-radius: 20px;
  position: relative;
  max-width: 35rem;
  font-family: 'Lato', Helvetica;
  margin-top: 3rem;
}

.message::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-width: 9px;
  border-color: transparent #D3E8DF transparent transparent;
  border-style: solid;
  left: -17px;
  top: 3rem;
}

.message.left::before {
  border-color: transparent transparent transparent #D3E8DF;
  left: initial;
  right: -17px;
}

.message .description {
  font-size: 1rem;
}

.section-steps-document {
  position: relative;
  width: 100%;
  height: 42rem;
}


.background-steps-document {
  display: block;
  position: relative;
  background-color: #198754;
  min-height: 42rem;
  width: 100%;
}

.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  margin-left: auto;
}

/* Stepper Styles */
.stepper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.step {
  display: flex;
  max-width: 30rem;
}

.step.mobile {
  height: 9.6rem;
  max-height: 9.6rem;
}

.step-number {
  min-width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: #fff;
  color: #198754;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2em;
  font-family: 'Lilita One', cursive;
}

.step.active .step-number {
  background-color: #F2B641;
  color: #fff;
}

.step-content {
  margin-left: 10px;
  color: #fff;
}

.step:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 2px;
  background-color: #fff;
  /* Puedes ajustar el color de la línea */
  z-index: -1;
}

.step p {
  font-size: .9rem;
}

/* Ocultar la línea en el primer paso */
.step:first-child:before {
  display: none;
}

.handler-step {
  display: flex;
  width: 60px;
  height: 46px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 0px 20px 20px 0px;
}

.handler-step.right {
  border-radius: 20px 0px 0px 20px;
}

@media screen and (max-width: 768px) {
  .section-steps-document {
    height: 50rem;
  }

  .background-steps-document {
    height: 35rem;
  }

  .video-section {
    width: 100%;
  }
}

.items-pricing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.card-pricing {
  display: block;
  min-width: 19rem;
  max-width: 19rem;
  height: 32rem;
  background-color: white;
  border-radius: 20px;
  font-family: 'Lato', Helvetica;
  margin: 10px;
}

.card-pricing.anual {
  height: 20rem;
}

.card-pricing .header {
  width: 100%;
  height: 3.87rem;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 10px 30px;
  border-radius: 20px 20px 0px 0px;
  color: #495057;
}

.card-pricing .body {
  display: block;
  width: 100%;
  height: calc(32rem - 3.87rem);
  padding: 15px;
  border-left: 2px solid #D3E8DF;
  border-right: 2px solid #D3E8DF;
  border-bottom: 2px solid #D3E8DF;
  border-radius: 0px 0px 20px 20px;
}

.card-pricing.anual .body {
  height: calc(20rem - 3.87rem);
}

@media screen and (max-width: 744px) {
  .card-pricing {
    height: 34rem;
  }

  .card-pricing.anual {
    height: 25rem;
  }

  .card-pricing .body {
    height: calc(34rem - 3.87rem);
  }

  .card-pricing.anual .body {
    height: calc(25rem - 3.87rem);
  }
}

/* Frequent Questions */

.block-questions {
  display: block;
  position: absolute;
  left: 0;
  top: 10%;
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem;
  width: 50%;
  font-family: 'Lato', Helvetica;
}

.block-answers {
  display: block;
  position: absolute;
  right: 5%;
  top: 0;
  background-color: #fff;
  border-radius: 20px;
  padding: 2rem;
  width: 50%;
  font-family: 'Lato', Helvetica;
  min-height: 7rem;
}

.card-question {
  cursor: pointer;
  padding-bottom: 12px;
}

.card-question .fa-circle {
  color: #b2e0cc;
}

.card-question:hover .fa-circle,
.card-question:hover .fa-chevron-right {
  color: #198754;
}

.card-question:hover .text-question {
  text-decoration: underline;
}

.card-question.active .fa-circle {
  color: #198754;
}

.accordion-question .header {
  font-weight: 500;
  padding-right: 10px;
}

/* Footer */

.footer {
  position: relative;
  width: 100%;
  height: 30rem;
  background-color: #D3E8DF;
}

.footer .content {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20rem;
}

@media screen and (max-width: 576px) {
  .footer {
    height: 42rem;
  }

  .footer .content {
    height: 36rem;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .footer {
    height: 42rem;
  }

  .footer .content {
    height: 31rem;
  }
}


@media screen and (min-width: 769px) and (max-width: 992px) {
  .footer {
    height: 40rem;
  }

  .footer .content {
    height: 30rem;
  }
}

.circle-footer-bench {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 12.5rem;
  height: 12.5rem;
  background-color: #fff;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.circle-footer-bench h1 {
  font-size: 2.5rem;
}

.social-media {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  color: #198754;
  border-radius: 50%;
  font-size: 1.3rem;
  margin-right: 10px;
}

.link-footer {
  text-decoration: none;
  color: #fff;
}

.link-footer:hover {
  text-decoration: underline;
  color: #fff;
}

/* Animated Button */
.animated-button {
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  transition: all ease 0.5s;
}

.animated-button:after,
.animated-button:before {
  width: 80px;
  height: 200px;
  content: '';
  display: block;
  background: rgba(255, 255, 255, 0.5);
  transform: rotate(45deg);
  top: -60px;
  position: absolute;
  transition: none;
  right: -45%;
  cursor: pointer;
}

.animated-button:before {
  left: -80%;
}

.animated-button:hover:after,
.animated-button:hover:before {
  transform: rotate(45deg), scaleX(130%);
  background: rgba(255, 255, 255, 0.1);
  transition: all ease 0.5s;
}

.animated-button:hover:after {
  right: 130%;
}

.animated-button:hover:before {
  left: 130%;
}

.section-register {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 4px 8px 5px rgb(138, 131, 231, .14);
}

.form-control,
.form-select {
  border: 1px solid #9f9d9d;
}

.green-input {
  background-color: #dbeae3;
  border: 1px solid #dbeae3;
}

.green-input:focus {
  background-color: #dbeae3;
  border: 1px solid #9ad1b7;
}

.textarea-style {
  max-height: 240px;
  min-height: 240px !important;
}

.card-contact {
  display: flex;
  margin-bottom: 10px;
}

.card-contact .circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 55px;
  color: #fff;
}

.card-contact .information {
  display: block;
  margin-left: 10px;
}